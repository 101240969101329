import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "..";

const statuses = {
  INACTIVE: 0,
  LOGIN_OK: 1,
  IN_CONNECT: 2,
  IN_MAP: 3
}

export const SuperGPS = () => {
  
  const { isAuth, URL_MAP, setAuth, proxy_layer_id } = useContext(AppContext);
  const [ inSuperGPS, setInSuperGPS ] = useState(false);
  const [ status, setStatus ] = useState(statuses.INACTIVE);
  


  const form = useRef();
  const iframe = useRef();  

  
  const handleLoad = (e) => {
    console.log({src: iframe.current.src, inSuperGPS, isAuth});

    if(status === statuses.INACTIVE){
      setStatus(statuses.LOGIN_OK);      
      setTimeout(() => {        
        setStatus(statuses.IN_CONNECT);
        iframe.current.src = `http://supergpsproxy.pablogonzalez.ar/?proxy_layer_id=${proxy_layer_id()}&proxy_layer_domain=plataforma.supergps.cl&proxy_layer_path=/map.php`;
        //form.current.submit();
      }, 5000);
    }

    if(status === statuses.IN_CONNECT){
      console.log("estaba en connect.php lo mande a map.php y termino. paso estado a IN_MAP")
      setStatus(statuses.IN_MAP);
    }

    if(status === statuses.IN_MAP){
      console.log("esta en map.php y hay un cambio de url estimo que es un logout. paso estado a INACTIVE y deslogueo")
      setAuth(false);
      setInSuperGPS(false);
      setStatus(statuses.INACTIVE);
    }

  }
  const display = useMemo(()=>isAuth ? "flex" : "none", [isAuth]);

  return (
    <>
      <form ref={form} action={URL_MAP} target="superGPS"></form>
      <div style={{
        width: "100%",
        height: "100%",
        padding: "0px",
        margin: "0px", 
        display
      }}>
        <iframe name="superGPS" onLoad={handleLoad} ref={iframe} style={{flex: 1, border: "0px"}}></iframe>
      </div>
    </>
  )

}