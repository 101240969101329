import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import '@splidejs/splide/dist/css/splide.min.css';
export * from "./components";
export * from "./pages";

const Index = () => {
  return <App/>
}

ReactDOM.render(
  <React.StrictMode>
    <Index/>
  </React.StrictMode>,
  document.getElementById('root')
);
