import react from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login, Layout, Recovery } from ".";

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>     
        <Route element={<Layout />}>
          <Route path="/*" element={<Login />}/>
          <Route path="/:client/*" element={<Login />}/>
          <Route path="/recovery" element={<Recovery />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}