import React, { useContext, useEffect, useRef, useState } from "react"
import axios from "axios";
import { AppContext } from "..";

export const Login = ({}) => {

  const { setAuth, isAuth, URL_MAP, URL_CONNECT, proxy_layer_id } = useContext(AppContext)
  const [type, setType] = useState("password");

  const [state, setState] = useState({
    username: "",
    password: "",
    cmd: "login",
    mobile: "false"
  });
  const form = useRef();
  const [error, setError] = useState("");
  const [loginOk, setLoginOk] = useState(false);

  const setStateValue = ({name, value}) => {
    setState(state => ({...state, [name]: value}));
  }

  useEffect(()=>{
    if(isAuth){
      var sGPS = window.open("", "superGPS");
      setTimeout(() => {
        form.current.submit();
        setTimeout(() => {
          sGPS.location.href = URL_MAP;
          setAuth(false);                 
          setLoginOk(false);
        }, 100);
      }, 100);
    }
  }, [isAuth]);


  const handleSubmit = (e) => {

    if(loginOk) return;

    e.preventDefault();
    e.stopPropagation();

    axios.post(
      `${window.constants.API_URL}/login`,
      Object.keys(state).map(key => `${key}=${state[key]}`).join("&")
    )
      .then(response => {
        console.log(response.data)
        if(response.data?.success){
          axios.post(`${window.constants.API_URL}/login`, "cmd=logout")
            .then(response => {
              setError("");
              setLoginOk(true);
              setAuth(true);

            }).catch(e=>{})      
        }else{
          setError("Error en el usuario o la contraseña ingresados.");
        }
      })
      .catch(e =>{
        console.log(e)
      })
    
    return false;
  }

  const toggleType = () => {
    console.log("click")
    setType(type => type === "password" ? "text" : "password");
  }

  return (
    <div className="page-login">

      <div class="text text-left text--main">
        <h2 class="adda-font-basic-bold">Hola!<span class="ilustration-apple_wave_hand"></span></h2>
      </div>
      <div class="text text-left text--subtitle margin-top-8">
        <p>
          Bienvenido al Portal de Acceso a <b class="highlighted">SuperGPS</b>. Inicia sesión en tu cuenta.
        </p>
      </div>
      <form ref={form} method="post" action={URL_CONNECT} target="superGPS" onSubmit={handleSubmit}>
        <input type="hidden" name="cmd" value={state.cmd}/>
        <input type="hidden" name="mobile" value={state.mobile}/>
        <div className="col-md">
          <div className="form-floating">
            <input type="text" className="form-control" id="username" name="username" placeholder="Ingresá email" value={state.username} onChange={({target})=>setStateValue(target)}/>
            <label for="username">Usuario</label>
          </div>
        </div>
        <div className="col-md mt-4">
          <div className="form-floating">
            <div className="position-absolute end-0 top-0" style={{zIndex: 1000, width: 50, height: 50}} onClick={toggleType} ></div>            
            <input type={type} className={`form-control ${type === "text" ? "hide" : "show"}-password`} id="password" name="password" placeholder="Ingresá contraseña" value={state.password} onChange={({target})=>setStateValue(target)}/>
            <label for="password">Contraseña</label>
          </div>
        </div>

        <div class="d-grid gap-2 py-4">
          <input type="submit" value="ingresar" className="btn btn-primary" />
        </div>
      </form>
      {error && 
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      }


    </div>

  )
}