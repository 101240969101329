import react from "react";

export const Column = ({children}) => {

  return (
    <div >
      {children}
    </div>
  )
}

