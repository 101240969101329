import { createContext, useEffect, useMemo, useState } from "react";
import { Column } from ".";
import { Outlet, useParams } from "react-router-dom";
import { SuperGPS } from "../pages";
import { Splide, SplideSlide } from '@splidejs/react-splide';

const URL_MAP = "https://plataforma.supergps.cl/map.php";
const URL_CONNECT = "https://plataforma.supergps.cl/func/connect.php"

const splideOptions = {
  type: 'loop',
  drag: false,
  autoplay: true,
  pauseOnHover: false,
  resetProgress: false,
  cover: true,
  height: '100vh',
  speed: 800,
  arrows: false
};

export const Layout = () => {

  const { client } = useParams();

  const [ isAuth, setIsAuth ] = useState(false);

  const setAuth = (value) => {
    setIsAuth(value);
  }

  
  const clientTheme = useMemo(()=>{
    return window.themes[client] || window.themes.theme_default;
  }, [client])

  return (
    <AppContext.Provider value={{
      isAuth,
      clientTheme,
      setAuth,   
      URL_MAP,
      URL_CONNECT
    }}>   
    <div className="layout">
      <div className="layout-column-slider d-none d-md-block">
        <Splide aria-label="slider" className="layout-column-slider-splide" options={splideOptions}>
          {clientTheme.login_imgs.map(image => (
            <SplideSlide>
              <img src={image} alt="Image"/>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="layout-column-outlet">
        <Outlet />
      </div>
    </div>
    </AppContext.Provider>
  )
}

export const AppContext = createContext({
  isAuth: false,
  loginOk: false,
  clientTheme: {},
  setAuth: (booleanValue) => {},
  URL_MAP,
  URL_CONNECT
})